@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Inter, ui-sans-serif;
}

.bg-wrapper {
  background-color: #22242c;
  color: white;
}

.bg-wrapper2 {
  background: linear-gradient(180deg, #ffffff 33.23%, #ffffff 67.61%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 100% 100%;
  padding-bottom: 100px;
}

.pb-fix {
  padding-bottom: 0;
}

.bg-wrapper-reverse {
  background: linear-gradient(180deg, #231c5e 33.23%, #202045 67.61%);
  color: white;
}

.button-landing {
  background: linear-gradient(
    86.77deg,
    rgb(66, 232, 224) -46.37%,
    rgb(105, 64, 222) 98.59%
  );
}

.pill {
  &:hover {
    background: linear-gradient(
      86.77deg,
      rgb(66, 232, 224) -46.37%,
      rgb(105, 64, 222) 98.59%
    );

    & svg {
      & * {
        stroke: white;
      }
    }
  }
}

.margin-fix {
  margin: 0 !important;
}

#vl_popup {
  @apply bg-gray-90 bg-opacity-80;
}

.pill-secondary {
  @apply rounded-lg;

  padding: 2px;
  background: linear-gradient(
    185.33deg,
    #42e8e0 -7.27%,
    #6940de 68.22%,
    #6940de 142.15%
  );

  .pill-secondary-border-wrap {
    @apply bg-white rounded-md p-8;
  }
}

.containerLayout {
  min-height: calc(100vh - 2.65rem);
}

.grid-fix {
  padding-left: 8%;
}

// Privacypolicy section

.terms {
  width: 90%;
  padding-top: 4rem;
  padding-left: 7%;
  position: relative !important;

  & .terms-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    padding: 0 3rem;
    color: #002f5c;

    &::before {
      content: url("/bg_circle_left.svg");
      transform: rotate(180deg);
      position: absolute;
      right: -15%;
      z-index: -1;
      overflow: hidden;
    }
  }

  & .first-line {
    width: 100%;
    text-align: justify;
    padding-top: 3rem;
    font-size: 1rem;
    padding-bottom: 1.5rem;
  }

  & .first-line::before {
    content: url("/DotsBox.svg");
    position: absolute;
    left: 0;
    top: 5%;
    z-index: -1;
  }

  & .privacy::before {
    content: url("/DotsBox.svg");
    position: absolute;
    left: 93%;
    top: 25%;
    z-index: -1;
  }

  & .class-action::before {
    content: url("/DotsBox.svg");
    position: absolute;
    left: 90%;
    top: 80%;
    z-index: -1;
  }

  & .indemnity::before {
    content: url("/DotsBox.svg");
    position: absolute;
    left: 20%;
    z-index: -1;
  }

  & .second-line {
    width: 100%;
    text-align: justify;
    padding-top: 0;
    font-size: 1rem;
    padding-bottom: 1.5rem;
  }

  & .ordered {
    font-size: 1rem;

    li {
      padding-bottom: 1.5rem;
      text-align: justify;

      &::marker {
        font-size: 1.1rem;
        font-weight: bold;
        color: #4a87c6;
      }
    }
  }

  & .list-subtitle {
    font-size: 1.1rem;
    font-weight: bold;
    color: #4a87c6;
    margin-bottom: 5px;
  }

  & .bulletlist-list {
    margin-top: 1.5rem;
  }

  & .bulletlist-item {
    padding: 0 5px;
  }

  & .bulletlist-item::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #4b5d68; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }

  & .bulletlist-subtitle {
    font-weight: bold;
    color: #4b5d68;
  }

  & .eligibility::before {
    content: "\25CF";
    position: absolute;
    font-size: 1000px;
    left: -10%;
    top: -15%;
    color: rgba(241, 248, 255, 0.2);
    z-index: -1;
  }

  & .prohibited::before {
    content: url("/bg_circle_left.svg");
    position: absolute;
    left: -10%;
    z-index: -1;
  }

  & .compliance-obl::before {
    content: url("/bg_circle_left.svg");
    transform: rotate(180deg);
    position: absolute;
    z-index: -1;
  }

  & .dispute::before {
    content: url("/bg_circle_left.svg");
    transform: rotate(180deg);
    position: absolute;
    right: -15%;
    z-index: -1;
  }

  & .bg-circle-right {
    position: absolute;
    left: -3vw;
  }
}

.input-phone-number input:focus{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
  color: black;
}
.input-phone-number{
  margin-left: 1em;
}

@media (max-width: 767px) {
  .resposive-image {
    object-fit: contain !important;
  }

  #bannMediumScreen {
    margin-bottom: -8.4em;
  }

  .terms {
    & .terms-title {
      font-size: 2rem;
      padding: 2rem 0 0;
      height: 30px;

      &::before {
        content: url("/DotsBox.svg");
        position: absolute;
        left: 90%;
      }
    }

    & .ordered {
      li {
        margin-left: -1rem;
      }
    }

    & .first-line::before {
      content: url("/DotsBox.svg");
      position: absolute;
      height: 10px;
      width: 10px;
      left: -20%;
      top: 5%;
      z-index: -1;
    }

    & .privacy::before {
      content: url("/DotsBox.svg");
      position: absolute;
      left: 93%;
      top: 25%;
    }

    & .class-action::before {
      content: url("/DotsBox.svg");
      position: absolute;
      left: 90%;
      top: 80%;
    }

    & .eligibility::before {
      content: none;
    }

    & .compliance-obl::before {
      left: -50%;
      transform: rotate(0deg);
    }

    & .dispute::before {
      right: -50%;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

.dark .changeAutoFillColor {
  color: white;
  -webkit-text-fill-color: white;

  &::placeholder {
    color: gray;
    -webkit-text-fill-color: gray;
  }
}

.iti__flag {
  background-image: url("/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("/flags@2x.png");
  }
}

#snackbar.show {
  -webkit-animation: fadein 0.5s, fadeout 0.5s 3.5s;
  animation: fadein 0.5s, fadeout 0.5s 3.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera, except for containers inside show-scroll-element class on mobile */
:not(.show-scroll-element div)::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar on elements inside show-scroll-element class outside of mobile resolution */
@media (min-width: 768px) {
  .show-scroll-element div {
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.panel {
  @apply relative flex flex-col dark:bg-gray-dashboard bg-light-transparent rounded-xl dark:text-white;
}

.containerFullCentered {
  @apply w-full h-full flex items-center justify-center;
}

.errorMsg {
  @apply text-red-primary text-sm;
}

.themedText {
  @apply text-black dark:text-white;
}

.mapBg {
  @apply text-white bg-[#22242C] grid grid-cols-1 lg:grid-cols-2 gap-8 lg:px-24 items-end justify-items-center lg:justify-items-start px-6 sm:px-12 pt-12 lg:p-4;
  background-image: url("/Vectornew.svg");
  background-repeat: no-repeat;
}
